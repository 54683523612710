/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .col-button {
    width: 33.33% !important
  }

  .col-1 {
    width: 8.3% !important
  }

  .col-2 {
    width: 100% !important
  }

  .col-3 {
    width: 100% !important
  }

  .col-4 {
    width: 100% !important
  }

  .col-5 {
    width: 100% !important
  }

  .col-6 {
    width: 100% !important
  }

  .col-7 {
    width: 100% !important
  }

  .col-8 {
    width: 100% !important
  }

  .col-9 {
    width: 100% !important
  }

  .col-10 {
    width: 100% !important
  }

  .col-11 {
    width: 100% !important
  }

  .col-12 {
    width: 100% !important
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {

  /* .col-button {width: 8.3% !important} */
  .col-1 {
    width: 8.3% !important
  }

  .col-2 {
    width: 75% !important
  }

  .col-3 {
    width: 75% !important
  }

  .col-4 {
    width: 75% !important
  }

  .col-5 {
    width: 100% !important
  }

  .col-6 {
    width: 100% !important
  }

  .col-7 {
    width: 100% !important
  }

  .col-8 {
    width: 100% !important
  }

  .col-9 {
    width: 100% !important
  }

  .col-10 {
    width: 100% !important
  }

  .col-11 {
    width: 100% !important
  }

  .col-12 {
    width: 100% !important
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {

  /* .col-button {width: 8.3% !important} */
  .col-1 {
    width: 33.33% !important
  }

  .col-2 {
    width: 33.33% !important
  }

  .col-3 {
    width: 33.33% !important
  }

  .col-4 {
    width: 50% !important
  }

  .col-5 {
    width: 50% !important
  }

  .col-6 {
    width: 50% !important
  }

  .col-7 {
    width: 58.33% !important
  }

  .col-8 {
    width: 66.66% !important
  }

  .col-9 {
    width: 75% !important
  }

  .col-10 {
    width: 83.33% !important
  }

  .col-11 {
    width: 91.66% !important
  }

  .col-12 {
    width: 100% !important
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {

  /* .col-button {width: 100% !important} */
  .col-1 {
    width: 8.3% !important
  }

  .col-2 {
    width: 16.6% !important
  }

  .col-3 {
    width: 25% !important
  }

  .col-4 {
    width: 33.33% !important
  }

  .col-5 {
    width: 41.66% !important
  }

  .col-6 {
    width: 50% !important
  }

  .col-7 {
    width: 58.33% !important
  }

  .col-8 {
    width: 66.66% !important
  }

  .col-9 {
    width: 75% !important
  }

  .col-10 {
    width: 83.33% !important
  }

  .col-11 {
    width: 91.66% !important
  }

  .col-12 {
    width: 100% !important
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

  /* .col-button {width: 8.3% !important} */
  .col-1 {
    width: 8.3% !important
  }

  .col-2 {
    width: 16.6% !important
  }

  .col-3 {
    width: 25% !important
  }

  .col-4 {
    width: 33.33% !important
  }

  .col-5 {
    width: 41.66% !important
  }

  .col-6 {
    width: 50% !important
  }

  .col-7 {
    width: 58.33% !important
  }

  .col-8 {
    width: 66.66% !important
  }

  .col-9 {
    width: 75% !important
  }

  .col-10 {
    width: 83.33% !important
  }

  .col-11 {
    width: 91.66% !important
  }

  .col-12 {
    width: 100% !important
  }

}


body {
  height: 100%;
  min-height: 100%;
  font-size: 100% !important;
}

.footer {
  height: 100px;
  margin-top: 0px;
  background-color: #80CBC4;
}

.width-100 {
  width: 100px;
}

.width-150 {
  width: 150px;
}

.width-200 {
  width: 200px;
  min-width: 100px;
}

.width100p {
  width: 100%
}

.width90p {
  width: 90%;
}


.width-1 {
  width: 8.3% !important
}

.width-2 {
  width: 16.6% !important
}

.width-3 {
  width: 25% !important
}

.width-4 {
  width: 33.33% !important
}

.width-5 {
  width: 41.66% !important
}

.width-6 {
  width: 50% !important
}

.width-7 {
  width: 58.33% !important
}

.width-8 {
  width: 66.66% !important
}

.width-9 {
  width: 75% !important
}

.width-10 {
  width: 83.33% !important
}

.width-11 {
  width: 91.66% !important
}

.width-12 {
  width: 100% !important
}

.width-button-werbinich {
  width: 100% !important;
  background-color: #07bc0c;
}

.height250 {
  height: 250px;
}

.height140 {
  height: 140px;
}

.height-100 {
  padding: 30px;
}

.fontWeightLighter {
  font-weight: lighter;
}

.fontWeight400 {
  font-weight: 400;
}

.bold {
  font-weight: bold;
}

.larger-font {
  font-size: larger;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.image-container {
  position: relative;
  text-align: start;
  color: white;
}

.svg-icons {
  transform: scale(1.8);
}

.kontakt-div {
  background-color: #f8f9fa;
  border-radius: 0.25rem;
  margin: 0 2% 0 2%;
}

.fontSizeLarger {
  font-size: larger;
}

.page-content {
  min-height: 100vh;
}

.page-content-bg {
  background-color: #263238 !important;
  min-height: 100vh;
}

.page-content {
  min-height: 100vh;
}

.homepage-cards {
  background-color: #80CBC4;
  font-weight: bold;
  border: 1px solid #b1b1b1;
  border-radius: 2.0rem;
  /* min-width: 300px;
  max-width: 400px; */
  padding: 20px;
  text-align: center;
}

.spiel-cards {
  background-color: #fff;
  font-weight: bold;
  border: 1px solid #f1f1f1;
  border-radius: 2.0rem;
  padding: 20px;
  text-align: center;
  height: 300px;
  margin: 0 5% 0 5%;
}

.spiel-cards-wahrheit-pflicht {
  background-color: #fff;
  font-weight: bold;
  border: 1px solid #f1f1f1;
  border-radius: 2.0rem;
  padding: 20px;
  text-align: center;
  height: 450px;
}

.spiel-cards-hoeher-tiefer {
  background-color: #fff;
  font-weight: bold;
  border: 1px solid #f1f1f1;
  border-radius: 2.0rem;
  padding: 20px;
  text-align: center;
  height: 500px;
}

.spiel-cards-werbinich {
  background-color: #fff;
  font-weight: bold;
  border: 1px solid #f1f1f1;
  border-radius: 2.0rem;
  padding: 20px;
  text-align: center;
  height: 480px;
  margin: 0 5% 0 5%;
}

.spiel-cards-busfahrer {
  background-color: #fff;
  font-weight: bold;
  border: 1px solid #f1f1f1;
  border-radius: 2.0rem;
  padding: 20px;
  text-align: center;
  height: 530px;
  margin: 0 .5% 0 .5%;
}


.kontakt-cards {
  background-color: rgb(245, 245, 245);
  /*font-weight: bold;*/
  border: 1px solid #838383;
  border-radius: 2.0rem;
  padding: 20px;
  text-align: center;
  margin: 0 5% 0 5%;
}

.nav-link-tab {
  display: block;
  padding: .5rem 1rem;
  color: #fff !important;
  text-decoration: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
  transition-property: color, background-color, border-color;
  transition-duration: 0.15s, 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out;
  transition-delay: 0s, 0s, 0s;
}

.nav-link-tab.active {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #dee2e6 #dee2e6 #fff !important;
}

.navbar-custom {
  background-color: #80CBC4;
}

.background-secondary {
  background-color: #80CBC4 !important;
}

.btn-werbinich {
  height: 62px !important;
}

.btn-bg-primary {
  color: #000 !important;
  background-color: #80CBC4 !important;
  border-color: #263238 !important;
  border-radius: 1.0em !important;
  /* width: 60%; */
}

.btn-bg-play {
  color: #FFF !important;
  background-color: #263238 !important;
  border-color: #263238 !important;
  border-radius: 1.0em !important;
}

.btn-bg-grey {
  color: #000 !important;
  background-color: #adb5bd !important;
  border-color: #263238 !important;
  border-radius: 1.0em !important;
}

.btn-bg-secondary {
  color: #fff !important;
  background-color: #263238 !important;
  border-color: #263238 !important;
  border-radius: 1.0rem !important;
}

.btn-bg-anleitung {
  color: #263238 !important;
  background-color: #80CBC4 !important;
  border-color: #263238 !important;
  border-radius: 1.0em !important;
}

.btn-bg-schließen {
  color: #fff !important;
  background-color: #FF5722 !important;
  border-color: #263238 !important;
  border-radius: 1.0rem !important;
}

.divider {
  height: 1px;
  background-color: #263238;
}

.divider-grey {
  height: 1px;
  background-color: #adb5bd;
  ;
}

.disable-padding {
  --bs-gutter-x: 0 !important;
}

.font-over-img {
  position: absolute;
  font-size: 4vw;
  padding: 4.5% 4.5% 0 10%;
  /* padding-top: 8%; */
  padding-bottom: 5%;
}

.loading {
  height: 3rem;
  width: 3rem;
}

.hidden-wahrheit {
  border-radius: 1.0rem;
  background-color: #000;
}

.hidden-pflicht {
  border-radius: 1.0rem;
  background-color: #000;
}

.wahrheit-font {
  color: #fff;
  font-size: 200%;
  padding-top: 48px;
}

.pflicht-font {
  color: #fff;
  font-size: 200%;
  padding-top: 48px;
}

.bierbutton {
  height: 32px;
  width: 36px;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.btn-bierbutton {
  color: #fff !important;
  background-color: #80CBC4 !important;
  border-color: #263238 !important;
  border-radius: 1.25rem !important;
}

.fontSizeLarger-200 {
  font-size: 200%;
}

.trinkaufforderung {
  height: 320px;
}

.centered {
  text-align: center;
}

.center-content {
  justify-content: center !important;
}

.flipped-img {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.vertical-align {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.content-after::after {
  content: attr(value);
}

li {
  list-style: none;
}

.mb-0 {
  margin-bottom: 0;
}

.margin-overall {
  margin: 0 5% 0 5%;
}

.padding-overall {
  padding: 0 5% 0 5%;
}

.font-size-30 {
  font-size: 30px;
}

.text-correct {
  color: #07bc0c;
}

.text-false {
  color: #e74c3c
}

.rotieren {
  transform: rotate(180deg);
}

.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@font-face {
  font-family: 'VT323';
  /* Gewünschter Name */
  src: url('./fonts/VT323-Regular.ttf') format('truetype');
  /* für Safari, Android, iOS */
}

.count-txt {
  font-family: 'VT323';
  font-size: 5em;
  color: #FF5722;
}

.universal-divider {
  margin: 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.stickman-img {
  height: 12vh;
}

.busfahrer-cards {
  flex: 0 0 !important;
}

/* * {
  box-sizing: border-box;
} */
:root {
  --darkBg: #fc3;
  --lightBg: #fff3ce;
  --darkRed: #e22929;
  --lightRed: #ffd0d0;
  --darkBlack: #000;
  --lightBlack: #777;
}

.cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 12px 12px;
  perspective: 1000px;
}

.card {
  display: block;
  width: 40px;
  height: 58px;
  /* margin-top: 5px; */
  position: relative;
  font-family: "Syne Mono", monospace;
  cursor: pointer;
}

.front,
.back {
  position: absolute;
  left: 0;
  top: 20;
  width: 100%;
  height: 100%;
  filter: drop-shadow(0 0 3px silver);
  backface-visibility: hidden;
  transition: all 0.5s;
}

.front {
  transform: rotateY(-180deg);
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"]:checked~.front {
  transform: rotateY(0deg);
}

input[type="checkbox"]:checked~.back {
  transform: rotateY(180deg);
}

.shadow {
  filter: drop-shadow(0 0 5px silver);
}

.shadow-inside {
  box-shadow: inset(0 0 5px silver);
}

.myCards {
  background: #fff;
  display: block;
  filter: drop-shadow(0 0 3px silver);
}

.myCardsDiv {
  display: block;
  width: 0px;
  height: 0px;
  margin-top: 30px;
}

.modal-img {
  margin-top: 50px;
  width: 100%;
  height: auto;
}


.fontSizeXXLarge {
  font-size: xx-large;
}